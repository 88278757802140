import React from 'react'
import PropTypes from 'prop-types'
import withLocation from '../../utils/withLocation'
import ToTree from '../../utils/convertToTree'
import axios from 'axios'
//import { MDBDataTableV5 } from 'mdbreact';

import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  FilteringState,
  IntegratedFiltering,
  TreeDataState,
  CustomTreeData,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableTreeColumn,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';

// import '@fortawesome/fontawesome-free/css/all.min.css'
// import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'mdbreact/dist/css/mdb.css'

// import Layout from '../../components/Layout'
// import SEO from '../../components/SEO/SEO'
// import jsonData from '../../data/articles/articles.json'

// setup config for api request
// const config = {
//   method: 'get',
//   url: 'https://www.ilo.org/sdmx/rest/codelist/ILO/CL_OCU',
//   headers: {
//     // eslint-disable-next-line quote-props
//     'Accept': 'application/vnd.sdmx.structure+json;version=1.0',
//     'Accept-Language': 'en',
//   },
// }

const config = {
  method: 'get',
  url: 'https://www.ilo.org/data-api/rest/v1/codelist/SCO/ISCO08?locale=fr',
}

class Codelist extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      codes: []
    }
  }

  componentDidMount() {
    this.getCodelist()
  }

  getCodelist = async() => {
    // fetch CL_OCU codelist from SDMX, once it retrieves resolve the promsie and update the state
    await axios(config).then((response) => {
      if (response.status === 200) {
        this.setState({ codes: ToTree(response.data) })
      }
    }).catch(error => {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.error(error)
    })
  }

  render() {
    const { codes } = this.state

    const getChildRows = (row, rootRows) => (row ? row.children : rootRows);

    // const data = {
    //   columns: [
    //     { name: 'code', title: 'Code' },
    //     { name: 'name', title: 'Name' },
    //     { name: 'includedOccupations', title: 'Included Occupations' },
    //     { name: 'excludedOccupations', title: 'Excluded Occupations' }
    //   ],
    //   rows: codes,
    // }

    const columns = [
      { name: 'code', title: 'Code' },
      { name: 'name', title: 'Name' },
      { name: 'includedOccupations', title: 'Included Occupations', width: '1000' },
      { name: 'excludedOccupations', title: 'Excluded Occupations', width: '1000' },
    ];
    const data = codes;
    const tableColumnExtensions = [
      { columnName: 'code', width: 250 },
      { columnName: 'includedOccupations', wordWrapEnabled: true },
      { columnName: 'excludedOccupations', wordWrapEnabled: true },
    ];

    const [filteringStateColumnExtensions] = [
      { columnName: 'code', filteringEnabled: false },
    ];

    const searchColumns = [
      { name: 'code', title: 'Code' },
      { name: 'name', title: 'Name' },
      { name: 'includedOccupations', title: 'Included Occupations' },
    ];

    //const [searchValue, setSearchState] = 'Female';

    // var dataMarkdown = [];
    // if (this.props.data !== null) {
    //   dataMarkdown = this.props.data.markdownRemark
    // }
    // const jsonData = this.props.data.allArticlesJson.edges[0].node.articles;
    // const { frontmatter, fields } = dataMarkdown;
    // const image = frontmatter.image.childImageSharp.fluid.src;
    // const langKey = frontmatter.lang;
    // const tags = frontmatter.tags;

    return (
      // <Layout className="container" data={this.props.data} jsonData={jsonData} location={this.props.location}>
      //   <SEO title="ISCO-08 Code List" />
        <section className="section">
          <Paper>
            <Grid
              rows={data}
              columns={columns}
            >
              <SearchState />
              <FilteringState />
              <IntegratedFiltering />
              <TreeDataState />
              <CustomTreeData
                getChildRows={getChildRows}
              />
              <Table
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow />
              <TableFilterRow />
              <TableTreeColumn
                for="code"
              />
              <Toolbar />
              <SearchPanel />
            </Grid>
          </Paper>
          {/* <MDBDataTableV5
            striped
            bordered
            small
            searchTop searchBottom={false}
            data={data}
          >
          </MDBDataTableV5> */}
          {/* {codes.map((code) => {
            return (
              <div key={code.code}><br />
                <p key={code.code} style={{ marginLeft: "25px" }}>{`${code.code} - ${code.name}`}</p>
                <ul>
                {code.children.map((child1) => {
                  return (
                    <li key={child1.code} style={{ marginLeft: "50px", listStyleType: "disc" }}>{`${child1.code} - ${child1.name}`}
                      <ul>
                        {child1.children.map((child2) => {
                          return (
                            <li key={child2.code} style={{ marginLeft: "50px", listStyleType: "circle" }}>{`${child2.code} - ${child2.name}`}
                              <ul>
                                {child2.children.map((child3) => {
                                  return <li key={child3.code} style={{ marginLeft: "50px", listStyleType: "square" }}>{`${child3.code} - ${child3.name}`}</li>
                                })}
                              </ul>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
                </ul>
              </div>
            )
          })} */}
        </section>
      // </Layout>
    )
  }
}

Codelist.propTypes = {
  search: PropTypes.object,
  // data: PropTypes.object.isRequired,
}

export default withLocation(Codelist)
